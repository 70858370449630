import React from 'react'
import Helmet from 'react-helmet'
import documentTitle from '../../utils/documentTitle'
import Layout from '../../layouts'

import PageWrapper from '../../components/PageWrapper'
import { TextOnlyContainer } from '../../components/common/Texts'
import ArticleHeader from '../../components/common/ArticleHeader'
import Link from '../../components/common/Link'

import imgMain from '../../images/logos/gtan.png'

//
//  Main
export default () => (
  <Layout>
    <Helmet>
      <title>
        {documentTitle(
          'Michelle McCorkell on the GTAN’s Women Invest Today Program'
        )}
      </title>
      <meta property="og:image:secure_url" content={imgMain} />
    </Helmet>

    <PageWrapper>
      <ArticleHeader
        date="July 11, 2018"
        title="Michelle McCorkell on the GTAN’s Women Invest Today Program"
        subtitle="A Conversation with Michelle McCorkell, GTAN’s Membership Services Manager"
        imgSrc={imgMain}
        imgAlt="GTAN WIT"
      />
      <section>
        <TextOnlyContainer>
          <p>
            When it comes to startups and innovation, Kitchener-Waterloo is
            among the top technology hubs in the world. We link to the country’s
            biggest, most diverse city and are one of the leading centres for
            education and innovation globally.
          </p>

          <p>
            But this wasn’t the case when{' '}
            <Link href="https://goldentriangleangelnet.ca/">
              Golden Triangle Angel Network (GTAN)
            </Link>{' '}
            was founded in 2009. With the economic downturn of 2008, the
            community faced new challenges, not the least of which was access to
            investment capital.
          </p>

          <p>
            Historically, the funding needs of early-stage tech companies in our
            area were well-served by a number of organizations, such as
            Communitech, The Accelerator Network, Infusion Angels, and
            individuals in the area with a strong track record of building tech
            companies.
          </p>

          <p>
            But even with this impressive group, a significant funding gap
            remained. GTAN was created to tackle this.
          </p>

          <p>
            Next year is our 10th anniversary, and we’re very proud of what
            we’ve achieved with our members and entrepreneurs. We’ve invested
            $35 million in over 75 companies and have a membership base of over
            80.
          </p>

          <h3>
            What is your reaction to the Angel Network data in the report?
          </h3>
          <p>
            Angel investment is the most significant source of risk capital for
            startup and early-stage businesses across North America, bringing
            not only finance, but vital business experience and expertise to
            enable businesses to achieve their high growth potential. Yet the
            proportion of women angels of the total number of angel investors
            remains extremely low.
          </p>

          <p>These numbers remind us of this.</p>

          <p>
            Awareness raising and recruitment to attract more women to angel
            investing is a critical next step. Getting more women to become
            investors in early-stage startups will help increase the number of
            female leaders in the tech sector, and I believe it’ll also lead to
            higher returns.
          </p>

          <h3>What is GTAN trying to do to change these numbers?</h3>
          <p>
            GTAN|WIT – Women Invest Today is a program to engage, educate and
            motivate women to pursue angel investing.
          </p>

          <p>
            It was a three-part event that had female investors and
            entrepreneurs engage in important conversations surrounding female
            representation in angel investing.
          </p>

          <p>
            GTAN started this program because the industry talks a lot about the
            growth of female entrepreneurship, but without representation on
            both sides of the table, among both entrepreneurs and investors, we
            won’t see real change. As a leading angel group in the start-up
            ecosystem, we knew we needed to do something.
          </p>

          <p>
            GTAN’s hope is that by hosting events like GTAN| WIT we will
            diversify our own group and the angel ecosystem in the KW region.
            Our goal is to get more women to the table.
          </p>

          <p>
            The verdict is in: diversity in tech is good for everyone. As tech
            investors become more diverse, the number of funded entrepreneurs
            who don’t fit the traditional stereotypes will rise, adding to the
            diversity of the whole industry.
          </p>

          <p>
            I look forward to a future where the data Female Funders and
            Hockeystick gather announces a 50/50 split in male/female angel
            investors, and also points to the positive impacts that come from
            having diverse perspectives on both sides of the table.
          </p>

          <hr />

          <p>
            As told to{' '}
            <Link href="https://www.hockeystick.co/">Hockeystick’s</Link>{' '}
            <Link href="https://www.linkedin.com/in/lauracurk/">
              Laura Curk
            </Link>
          </p>
        </TextOnlyContainer>
      </section>
    </PageWrapper>
  </Layout>
)
